html, body {
  font: $base-font-size $base-font-family;
  color: $base-font-color;
  overflow: hidden;

  @include xs {
    font-size: $base-font-size - 4;
  }
}

.slider {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include xs {
    border-left-width: 1rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 3rem;
    background: $orange-box;
    mix-blend-mode: multiply;
    z-index: 99;
    opacity: 1;
    transition: all .4s ease;
    transform: translate3d(0,0,0);

    @at-root .home & {
      transform: translate3d(200%,0,0);
    }
    @at-root .contact & {
      transform: translate3d(200%,0,0);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3rem;
    background: #fff;
    mix-blend-mode: lighten;
    z-index: 99;
    transform: all .5s ease;
    transition: all .4s ease;
    opacity: .75;
    
    @at-root .home & {
      transform: translate3d(200%,0,0);
    }

    // @at-root .home & { width: 45%; }
    // @at-root .contact & { opacity: 1 }

  }
}
