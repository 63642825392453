.slide--home {
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 20;

  h1 {
    transform: matrix(1, 0, 0, 1, 0, 0);
    color: $black-text;

    &:after {
      display: none;
    }
  }

  @include md {
    width: 62.5%;
    box-shadow: 45vw 0 30px 30px rgba(#fff, 0);
    transition: box-shadow .4s ease;

    @at-root .home & {
      box-shadow: 45vw 0 30px 30px rgba(#fff, .75);
    }
  }

  .slide-info-content {
    padding-top: 2rem !important;
  }

  .slide-info {
    justify-content: space-between;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    p {
      font-size: 1.25em;
      line-height: 1.3em;
      max-width: 23em;
    }
  }
}
