.grid {
  // margin: 0 $base-padding/2;
  @include clearfix;
}

[class*='col-'] {
  float: left;
  // padding: 0 $base-padding/2;
}


.col-1-1 {
  width: 100%;
  float: none;
}
.col-2-3 {
  width: 66.66%;
}
.col-1-3 {
  width: 33.33%;
}
.col-1-2 {
  width: 50%;
}
.col-1-4 {
  width: 25%;
}
.col-1-8 {
  width: 12.5%;
}

@include xs {
  .col-xs-1-1 {
    width: 100%;
    float: none;
  }
  .col-xs-2-3 {
    width: 66.66%;
  }
  .col-xs-1-3 {
    width: 33.33%;
  }
  .col-xs-1-2 {
    width: 50%;
  }
  .col-xs-1-4 {
    width: 25%;
  }
  .col-xs-1-8 {
    width: 12.5%;
  }
}

@include sm {
  .col-sm-1-1 {
    width: 100%;
    float: none;
  }
  .col-sm-2-3 {
    width: 66.66%;
  }
  .col-sm-1-3 {
    width: 33.33%;
  }
  .col-sm-1-2 {
    width: 50%;
  }
  .col-sm-1-4 {
    width: 25%;
  }
  .col-sm-1-8 {
    width: 12.5%;
  }
}

@include md {
  .col-md-1-1 {
    width: 100%;
    float: none;
  }
  .col-md-2-3 {
    width: 66.66%;
  }
  .col-md-1-3 {
    width: 33.33%;
  }
  .col-md-1-2 {
    width: 50%;
  }
  .col-md-1-4 {
    width: 25%;
  }
  .col-md-1-8 {
    width: 12.5%;
  }
}

@include lg {
  .col-lg-1-1 {
    width: 100%;
    float: none;
  }
  .col-lg-2-3 {
    width: 66.66%;
  }
  .col-lg-1-3 {
    width: 33.33%;
  }
  .col-lg-1-2 {
    width: 50%;
  }
  .col-lg-1-4 {
    width: 25%;
  }
  .col-lg-1-8 {
    width: 12.5%;
  }
}

@include xl {
  .col-xl-1-1 {
    width: 100%;
    float: none;
  }
  .col-xl-2-3 {
    width: 66.66%;
  }
  .col-xl-1-3 {
    width: 33.33%;
  }
  .col-xl-1-2 {
    width: 50%;
  }
  .col-xl-1-4 {
    width: 25%;
  }
  .col-xl-1-8 {
    width: 12.5%;
  }
}
