$beige-box: #F7F7F2;
$beige-text: $beige-box;
$orange-box: #ff5c39;
$orange-text: $orange-box;
$black-box: #222;
$black-text: $black-box;

$base-color: $orange-box;
$base-selection-color: #f2f2f2;
$base-font-color: #222;
$base-font-family: 'NS', "Avenir", sans-serif;
$title-font-family: "Times New Roman", serif;
$base-font-size: 18px;

$size-xs: 568px;
$size-sm: 768px;
$size-md: 960px;
$size-lg: 1200px;
$size-xl: 1500px;
