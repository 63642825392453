header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.75rem 3rem 0;
  z-index: 99;
  right: 0;

  @include xs {
    padding: 1.25rem 1.5rem;
  }
}
