@charset "UTF-8";
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::selection {
  background: #f2f2f2; }

::-moz-selection {
  background: #f2f2f2; }

.grid::before, .grid::after {
  content: " ";
  display: table; }

.grid::after {
  clear: both; }

[class*='col-'] {
  float: left; }

.col-1-1 {
  width: 100%;
  float: none; }

.col-2-3 {
  width: 66.66%; }

.col-1-3 {
  width: 33.33%; }

.col-1-2 {
  width: 50%; }

.col-1-4 {
  width: 25%; }

.col-1-8 {
  width: 12.5%; }

@media (max-width: 568px) {
  .col-xs-1-1 {
    width: 100%;
    float: none; }
  .col-xs-2-3 {
    width: 66.66%; }
  .col-xs-1-3 {
    width: 33.33%; }
  .col-xs-1-2 {
    width: 50%; }
  .col-xs-1-4 {
    width: 25%; }
  .col-xs-1-8 {
    width: 12.5%; } }

@media (min-width: 569px) {
  .col-sm-1-1 {
    width: 100%;
    float: none; }
  .col-sm-2-3 {
    width: 66.66%; }
  .col-sm-1-3 {
    width: 33.33%; }
  .col-sm-1-2 {
    width: 50%; }
  .col-sm-1-4 {
    width: 25%; }
  .col-sm-1-8 {
    width: 12.5%; } }

@media (min-width: 960px) {
  .col-md-1-1 {
    width: 100%;
    float: none; }
  .col-md-2-3 {
    width: 66.66%; }
  .col-md-1-3 {
    width: 33.33%; }
  .col-md-1-2 {
    width: 50%; }
  .col-md-1-4 {
    width: 25%; }
  .col-md-1-8 {
    width: 12.5%; } }

@media (min-width: 1200px) {
  .col-lg-1-1 {
    width: 100%;
    float: none; }
  .col-lg-2-3 {
    width: 66.66%; }
  .col-lg-1-3 {
    width: 33.33%; }
  .col-lg-1-2 {
    width: 50%; }
  .col-lg-1-4 {
    width: 25%; }
  .col-lg-1-8 {
    width: 12.5%; } }

@media (min-width: 1500px) {
  .col-xl-1-1 {
    width: 100%;
    float: none; }
  .col-xl-2-3 {
    width: 66.66%; }
  .col-xl-1-3 {
    width: 33.33%; }
  .col-xl-1-2 {
    width: 50%; }
  .col-xl-1-4 {
    width: 25%; }
  .col-xl-1-8 {
    width: 12.5%; } }

html, body {
  font: 18px "NS", "Avenir", sans-serif;
  color: #222;
  overflow: hidden; }
  @media (max-width: 568px) {
    html, body {
      font-size: 14px; } }

.slider {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media (max-width: 568px) {
    .slider {
      border-left-width: 1rem; } }
  .slider:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 3rem;
    background: #ff5c39;
    mix-blend-mode: multiply;
    z-index: 99;
    opacity: 1;
    transition: all .4s ease;
    transform: translate3d(0, 0, 0); }
    .home .slider:after {
      transform: translate3d(200%, 0, 0); }
    .contact .slider:after {
      transform: translate3d(200%, 0, 0); }
  .slider:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3rem;
    background: #fff;
    mix-blend-mode: lighten;
    z-index: 99;
    transform: all .5s ease;
    transition: all .4s ease;
    opacity: .75; }
    .home .slider:before {
      transform: translate3d(200%, 0, 0); }

@font-face {
  font-family: 'NS';
  src: url("../fonts/NeuzeitS-Regular.woff") format("woff"), url("../fonts/NeuzeitS-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'NS';
  src: url("../fonts/NeuzeitS-Italic.woff") format("woff"), url("../fonts/NeuzeitS-Italic.woff2") format("woff2");
  font-style: italic;
  font-weight: normal; }

h1,
h2 {
  font: 4.25rem/0.9 "Times New Roman", serif;
  letter-spacing: -.035em; }
  @media (max-width: 568px) {
    h1,
    h2 {
      font-size: 2.8rem; } }

h1:after {
  content: '’';
  color: #222;
  margin-left: -.05em;
  display: inline-block; }

p {
  line-height: 1.4em;
  margin: 0 0 .7em; }
  p:last-child {
    margin-bottom: -.25em; }

li {
  line-height: 1.2em;
  margin: 0 0 .5em; }
  li:last-child {
    margin-bottom: -.25em; }

li[data-prefix] {
  padding-left: 1.5em;
  position: relative; }
  li[data-prefix]:before {
    content: attr(data-prefix);
    position: absolute;
    top: -0.15em;
    left: 0;
    color: #222;
    font: bold 1.1em "Times New Roman", serif; }

a {
  color: inherit;
  text-decoration: none; }
  p a:hover {
    background-image: linear-gradient(transparent 50%, currentColor 50%);
    background-repeat: repeat-x;
    background-size: 1px 2px;
    background-position: 0 100%;
    text-decoration: none; }

p a {
  color: #ff5c39; }

em {
  font-style: italic; }

.text-orange {
  color: #ff5c39; }

.animate-opacity {
  opacity: 0;
  transition: opacity .25s ease; }

.animate-opacity-done {
  opacity: 1; }

header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.75rem 3rem 0;
  z-index: 99;
  right: 0; }
  @media (max-width: 568px) {
    header {
      padding: 1.25rem 1.5rem; } }

footer {
  position: absolute;
  bottom: 0;
  right: 3rem;
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
  line-height: 3.4rem;
  z-index: 111; }
  @media (min-width: 960px) {
    footer {
      padding: 0 3rem; } }
  footer nav {
    border-top: 2px solid #222;
    float: left; }
    @media (min-width: 960px) {
      footer nav.nav-slides {
        float: right; } }
  footer li {
    display: inline-block;
    padding-right: .5em; }
    footer li:last-child {
      padding-right: 0; }
  footer a.active {
    color: #ff5c39; }
  footer .counter {
    font-size: .95rem; }
  footer .divider {
    display: inline-block;
    width: .3em;
    margin-left: .2em; }
    footer .divider:after {
      content: '';
      position: relative;
      top: .4em;
      display: inline-block;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      height: 1.4em;
      transform: rotate(15deg); }
  footer .nav-arrow {
    position: relative;
    bottom: -7px;
    width: 1.55rem;
    height: 24px;
    display: inline-block;
    background: url("../images/slider-arrow.png") left center no-repeat;
    background-image: url("../images/slider-arrow.svg");
    background-size: 200% auto;
    transition: transform .2s ease; }
  footer .nav-arrow-left:hover {
    transform: translateX(-3px); }
  footer .nav-arrow-right {
    background-position: right center; }
    footer .nav-arrow-right:hover {
      transform: translateX(3px); }
  @media (max-width: 568px) {
    footer .nav-pages {
      display: none; } }

.slide {
  height: 100%;
  padding: 5rem 3rem 5.5rem;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 3rem;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  @media (max-width: 959px) {
    .slide {
      padding: 3.75rem 1.5rem; } }
  .slide:first-of-type {
    z-index: 1; }
  .slide:nth-of-type(2n) {
    background: #F7F7F2; }
  .slide h1 {
    width: calc(100% + 6rem);
    margin: 0 -3rem;
    padding: 0 3rem; }
    @media (max-width: 959px) {
      .slide h1 {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
        padding: 0 1.5rem; } }
  .slide h2 {
    margin-bottom: 1.75rem;
    color: #222; }
  .slide--home, .slide--contact {
    background: #ff5c39 !important;
    color: #fff; }
    .slide--home h1, .slide--contact h1 {
      color: #fff; }
    .slide--home p a, .slide--contact p a {
      color: #fff; }

.slide-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%; }
  @media (max-width: 959px) {
    .slide-container {
      flex-direction: column; } }

.slide-info {
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
  margin: 0 1px; }
  @media (max-width: 959px) {
    .slide-info {
      width: auto;
      height: 36%;
      flex-direction: column;
      flex-shrink: 1;
      flex-grow: 1; } }
  .slide-info-content {
    flex-shrink: 0;
    padding: 1.5rem 0 1.5rem 1.5rem;
    margin: 0; }
    @media (max-width: 959px) {
      .slide-info-content {
        padding: 2rem 0; } }
  .slide-info--right {
    justify-content: flex-end; }
    @media (max-width: 959px) {
      .slide-info--right {
        justify-content: flex-start; }
        .slide-info--right .image-fill {
          display: none; } }
  .slide-info--left {
    width: 64%;
    flex-shrink: 1; }
    @media (max-width: 959px) {
      .slide-info--left {
        width: auto;
        flex-shrink: 1;
        flex-grow: 1; } }

.image-fill {
  background-size: cover;
  background-position: center;
  transition: all .4s ease;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1; }

.btn-arrow {
  color: #ff5c39;
  padding-right: 1.25rem;
  position: relative;
  display: inline-block; }
  .btn-arrow span {
    display: inline-block;
    transition: all .2s ease; }
  .btn-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 1em;
    height: 100%;
    background: url("../images/btn-arrow.png") right center no-repeat;
    background-image: url("../images/btn-arrow.svg");
    background-size: 200% auto;
    transition: all .2s ease;
    transform: translate3d(0, 0, 0); }
  .btn-arrow:hover:after {
    transform: translate3d(0.2em, 0, 0); }

.slide--home {
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 20; }
  .slide--home h1 {
    transform: matrix(1, 0, 0, 1, 0, 0);
    color: #222; }
    .slide--home h1:after {
      display: none; }
  @media (min-width: 960px) {
    .slide--home {
      width: 62.5%;
      box-shadow: 45vw 0 30px 30px rgba(255, 255, 255, 0);
      transition: box-shadow .4s ease; }
      .home .slide--home {
        box-shadow: 45vw 0 30px 30px rgba(255, 255, 255, 0.75); } }
  .slide--home .slide-info-content {
    padding-top: 2rem !important; }
  .slide--home .slide-info {
    justify-content: space-between;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0; }
    .slide--home .slide-info p {
      font-size: 1.25em;
      line-height: 1.3em;
      max-width: 23em; }

@media (min-width: 960px) {
  .slide--contact .slide-container {
    flex-direction: row-reverse; } }

.slide--contact .slide-info-content {
  overflow: auto; }
  .slide--contact .slide-info-content div {
    height: 100%; }
  @media (min-width: 960px) {
    .slide--contact .slide-info-content {
      padding-left: 0;
      padding-right: 3rem;
      padding-top: 0;
      padding-bottom: 0; } }

@media (min-width: 960px) {
  .slide--contact .slide-info--left {
    width: 50%;
    margin-top: -8.5rem; } }

@media (min-width: 960px) {
  .slide--contact .slide-info--right {
    width: 50%; }
    .slide--contact .slide-info--right p {
      max-width: 24rem; } }

.archive-wrapper {
  height: calc(100vh - 13.5rem);
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media (max-width: 568px) {
    .archive-wrapper {
      height: calc(100vh - 12rem); } }

.archive-list {
  transform: translate3d(0, 0, 0);
  column-count: 3;
  column-fill: auto; }
  @media (max-width: 568px) {
    .archive-list {
      column-count: 1; } }
  .archive-list li {
    margin: 0;
    width: 100%;
    display: inline-block;
    margin: 0 0 1em; }
  .archive-list li time {
    margin-right: .75em; }
  .archive-list li span {
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
