@font-face {
    font-family: 'NS';
    src: url('../fonts/NeuzeitS-Regular.woff') format('woff'),
         url('../fonts/NeuzeitS-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'NS';
    src: url('../fonts/NeuzeitS-Italic.woff') format('woff'),
         url('../fonts/NeuzeitS-Italic.woff2') format('woff2');
    font-style: italic;
    font-weight: normal;
}


h1,
h2 {
  font: 4.25rem/.9 $title-font-family;
  letter-spacing: -.035em;

  @include xs {
    font-size: 2.8rem;
  }
}

h1 {
  &:after {
    content: '’';
    color: $black-text;
    margin-left: -.05em;
    display: inline-block;
  }
}

p {
  line-height: 1.4em;
  margin: 0 0 .7em;

  &:last-child {
    margin-bottom: -.25em;
  }
}

li {
  line-height: 1.2em;
  margin: 0 0 .5em;

  &:last-child {
    margin-bottom: -.25em;
  }
}

li[data-prefix] {
  padding-left: 1.5em;
  position: relative;

  &:before {
    content: attr(data-prefix);
    position: absolute;
    top: -0.15em;
    left: 0;
    color: $black-text;
    font: bold 1.1em "Times New Roman", serif;
  }
}

a {
  color: inherit;
  text-decoration: none;

  @at-root p &:hover {
    @include underline(currentColor, 2px, 100%);
  }
}

p a {
  color: $orange-text
}

em {
  font-style: italic;
}

.text-orange {
  color: $orange-text;
}
