.archive {

}

.archive-wrapper {
  height: calc(100vh - 13.5rem);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include xs {
    height: calc(100vh - 12rem);
  }
}

.archive-list {
  transform: translate3d(0,0,0);
  column-count: 3;
  column-fill: auto;

  @include xs {
    column-count: 1;
  }

  li {
    margin: 0;
    // display: table;
    // table-layout: fixed;
    width: 100%;
    display: inline-block;
    margin: 0 0 1em;
  }

  li time {
    margin-right: .75em;
    // width: 5.3em;
    // display: table-cell;
    // line-height: 1.3;

  }

  li span {
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // display: table-cell;
  }
}
