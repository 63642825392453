.btn-arrow {
  color: $base-color;
  padding-right: 1.25rem;
  position: relative;
  display: inline-block;

  span {
    display: inline-block;
    transition: all .2s ease;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 1em;
    height: 100%;
    background: url('../images/btn-arrow.png') right center no-repeat;
    background-image: url('../images/btn-arrow.svg');
    background-size: 200% auto;
    transition: all .2s ease;
    transform: translate3d(0,0,0);
  }

  &:hover:after {
    transform: translate3d(.2em,0,0);
  }
}
