footer {
  position: absolute;
  bottom: 0;
  right: 3rem;
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
  line-height: 3.4rem;
  z-index: 111;

  @include md {
    padding: 0 3rem;
  }

  nav {
    border-top: 2px solid $black-box;
    float: left;

    &.nav-slides {
      @include md {
        float: right;
      }
    }
  }

  li {
    display: inline-block;
    padding-right: .5em;

    &:last-child {
      padding-right: 0;
    }
  }

  a.active {
    color: $orange-text;
  }

  .counter {
    font-size: .95rem;
  }

  .divider {
    display: inline-block;
    width: .3em;
    margin-left: .2em;

    &:after {
      content: '';
      position: relative;
      top: .4em;
      display: inline-block;
      border-left: 1px solid rgba(#000, .2);
      height: 1.4em;
      // margin: 0 0 0 .1em;
      transform: rotate(15deg);
    }
  }

  .nav-arrow {
    position: relative;
    bottom: -7px;
    width: 1.55rem;
    height: 24px;
    display: inline-block;
    background: url('../images/slider-arrow.png') left center no-repeat;
    background-image: url('../images/slider-arrow.svg');
    background-size: 200% auto;
    transition: transform .2s ease;
  }

  .nav-arrow-left {
    &:hover {
      transform: translateX(-3px);
    }

  }

  .nav-arrow-right {
    background-position: right center;

    &:hover {
      transform: translateX(3px);
    }
  }

  @include xs {
    .nav-pages {
      display: none;
    }
  }
}
