$speed: .75s;

.slide {
  height: 100%;
  padding: 5rem 3rem 5.5rem;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 3rem;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include smmax {
    padding: 3.75rem 1.5rem;
  }

  &:first-of-type {
    z-index: 1;
  }

  &:nth-of-type(2n) {
    background: $beige-box;
  }

  h1 {
    width: calc(100% + 6rem);
    margin: 0 -3rem;
    padding: 0 3rem;

    @include smmax{
      width: calc(100% + 3rem);
      margin: 0 -1.5rem;
      padding: 0 1.5rem;
    }
  }

  h2 {
    margin-bottom: 1.75rem;
    color: $black-text;
  }

  &--home, &--contact {
    background: $orange-box !important;
    color: #fff;

    h1 {
      color: #fff;
    }

    p a {
      color: #fff;
    }
  }
}

.slide-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;

  @include smmax {
    flex-direction: column;
  }
}

.slide-info {
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
  margin: 0 1px;

  @include smmax {
    width: auto;
    height: 36%;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
  }

  &-content {
    flex-shrink: 0;
    // flex-grow: 1;
    padding: 1.5rem 0 1.5rem 1.5rem;
    margin: 0;

    @include smmax {
      padding: 2rem 0;
    }
  }

  &--right {
    justify-content: flex-end;

    @include smmax {
      justify-content: flex-start;

      .image-fill {
        display: none;
      }
    }
  }

  &--left {
    width: 64%;
    flex-shrink: 1;

    @include smmax {
      width: auto;
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
}

.image-fill {
  background-size: cover;
  background-position: center;
  transition: all .4s ease;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
}
