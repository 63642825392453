.slide--contact .slide-container {
  @include md {
    flex-direction: row-reverse;
  }
}

.slide--contact .slide-info-content {
  overflow: auto;
  
  div {
    height: 100%;
  }
  @include md {
    padding-left: 0;
    padding-right: 3rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.slide--contact .slide-info--left {


  @include md {
    width: 50%;
    margin-top: -8.5rem;
  }
}
.slide--contact .slide-info--right {
  @include md {
    width: 50%;

    p {
      max-width: 24rem;
    }
  }
}
